<!-- eslint-disable vue/valid-v-else-if -->
<template>
  <Card :id="'range-' + selectedCar.vin">
    <template v-slot:header>
      <div class="range-header">
        <div
          class="range-badge"
          :class="{ unavailable: !selectedCar.isAvailable }"
        >
          <span v-if="selectedCar.isAvailable">DISPONÍVEL EM STOCK</span
          ><span v-else>SOB ENCOMENDA</span>
        </div>
        <div
          @click="toogleFavorite"
          :class="isFavorite ? 'favorite on' : 'favorite'"
        >
          <div :class="isFavorite ? 'favorite-button on' : 'favorite-button'">
            <Heart />
          </div>
        </div>
        <div class="range-image-container-list">
          <ImageLoading v-if="imageLoading" />
          <ThreeSixtyImage
            v-if="imageLoaded && selectedCar.rotatingImages.length > 35"
            class="range-image-three"
            :imgs="selectedCar.rotatingImages"
            ref="threesixty"
            :key="componentUpdate"
          />
          <img
            v-else-if="!imageLoading && selectedCar.image"
            class="range-image"
            :src="selectedCar.image.url"
            :alt="selectedCar.model"
            :title="selectedCar.model"
            @click="start360"
          />
        </div>

        <div
          v-if="selectedCar.rotatingImages.length > 35"
          class="icon-container"
        >
          <Icon360 />
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="range-info">
        <p
          class="range-title"
          @click="$router.push('/novos/' + selectedCar.vin)"
        >
          {{ selectedCar.model }}
        </p>
        <p class="range-details">
          {{ selectedCar.version }}
        </p>
        <p class="range-color">
          <span class="red-bullet"> &bull; </span>{{ selectedCar.color
          }}<!-- <span>{{ data.color.split("/")[0] }}</span
          ><span class="red-bullet" v-if="data.color.split('/').length > 1">
            &bull; </span
          ><span>{{ data.color.split("/")[1] }}</span> -->
        </p>
        <hr />
        <div class="range-price-row">
          <div class="range-price-details" v-if="windowWidth <= 1600">
            <div class="range-price-full mt-1">PVP</div>
            <div class="legal-row">
              <div class="range-price-full">
                {{ formatPriceNumber(selectedCar.price, 2, "€") }}
              </div>
              <div class="info-legal left mt-0">
                <img :src="require('@/assets/info.svg')" />
                <p class="info-text-right" v-html="selectedCar.legalInfo"></p>
              </div>
            </div>
          </div>
          <div class="range-price-details">
            <div class="legal-row">
              <div class="info-legal">
                <img :src="require('@/assets/info.svg')" />
                <p class="info-text-right" v-html="selectedCar.legalInfo"></p>
              </div>
              <div class="range-price-full mt-0">
                Em {{ selectedCar.financialProduct }}
              </div>
            </div>
            <p class="range-price-month mt-0">{{ selectedCar.formattedFee }}</p>
            <p class="range-price-taeg">TAEG: {{ selectedCar.taeg }}</p>
          </div>
          <div class="range-price-details mt-0" v-if="windowWidth > 1600">
            <div class="range-price-full mt-0">PVP</div>
            <div class="legal-row">
              <div class="range-price-full">
                {{ formatPriceNumber(selectedCar.price, 2, "€") }}
              </div>
              <div class="info-legal left mt-0">
                <img :src="require('@/assets/info.svg')" />
                <p class="info-text-right" v-html="selectedCar.legalInfo"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="range-price-info">
          <p class="range-price-label">
            Entrada inicial:
            <span class="range-price-value">{{
              selectedCar.initEntrance
            }}</span>
          </p>
          <p class="range-price-label">
            Prazo:
            <span class="range-price-value"
              >{{ selectedCar.nrPayments }} meses</span
            >
          </p>
          <p class="range-price-label">
            Montante financiado:
            <span class="range-price-value">{{
              selectedCar.capitalToFinance
            }}</span>
          </p>
          <p
            class="range-price-label"
            v-if="selectedCar.idFinancialProduct == 1"
          >
            VFMG:
            <span class="range-price-value">{{
              selectedCar.vfmg ? selectedCar.vfmg : "0,00€"
            }}</span>
          </p>
        </div>

        <hr />
        <div v-if="alternativeArray.length > 1">
          <p>
            Outras cores<span v-if="!selectedCar.isAvailable">
              sob encomenda</span
            ><span v-else> para entrega imediata</span>
          </p>
        </div>

        <div class="image-variant-container" v-if="alternativeArray">
          <div
            style="margin-top: 0"
            v-for="(alternate, i) in alternativeArray"
            :key="i"
          >
            <img
              class="range-image-variant"
              v-if="alternate"
              :src="
                alternate.images.length > 4
                  ? alternate.images[4].url
                  : alternate.images[0].url
              "
              :alt="alternate.model"
              @click="alternateCar(alternate.vin)"
            />
          </div>
        </div>
        <p
          v-if="selectedCar.alternatives.length > 4"
          class="show-more-button"
          @click="isShowMore = !isShowMore"
        >
          <span v-if="!isShowMore">Ver mais cores</span>
          <span v-else>Mostrar menos</span>
        </p>
      </div>
    </template>
    <template v-slot:footer>
      <div class="range-cta row">
        <Button
          btnLabel="Mostrar detalhes"
          :btnLink="'/novos/' + selectedCar.vin"
          :btn="selectedCar.vin"
          :externalLink="false"
          btnClass="full-width-btn primary-btn "
        />
      </div>
    </template>
  </Card>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
import Card from "@/components/Card/Card.vue";
import ThreeSixtyImage from "@/components/Images/ThreeSixtyImage";
import ImageLoading from "@/components/Loading/ImageLoading";
import Heart from "@/assets/Heart.vue";
import Icon360 from "@/assets/Icon360.vue";
import { mapGetters } from "vuex";

import { formatPriceNumber } from "@/functions/general.js";

export default {
  name: "RangeSingleCard",
  data() {
    return {
      newCarInfo: null,
      imageLoading: false,
      imageLoaded: false,
      componentUpdate: 0,
      selectedCar: {},
      isFavorite: false,
      isShowMore: false,
      windowWidth: window.innerWidth,
    };
  },
  props: {
    data: { type: Object },
  },
  components: {
    Button,
    Card,
    ThreeSixtyImage,
    ImageLoading,
    Heart,
    Icon360,
  },
  computed: {
    ...mapGetters("user", {
      user: "getUserData",
    }),
    alternativeArray() {
      if (this.selectedCar.alternatives.length > 1) {
        let newArray = [];
        this.selectedCar.alternatives.forEach((element) => {
          if (element.vin != this.selectedCar.vin) {
            newArray.push(element);
          }
        });
        if (this.isShowMore) {
          return newArray;
        } else {
          return [newArray[0], newArray[1], newArray[2], newArray[3]];
        }
      } else {
        return [];
      }
    },
  },
  methods: {
    formatPriceNumber,
    start360() {
      if (this.selectedCar.rotatingImages) {
        if (this.selectedCar.rotatingImages.length > 1) {
          this.imageLoading = true;
          setTimeout(() => {
            this.imageLoaded = true;
            this.imageLoading = false;
          }, 1500);
        }
      }
    },
    toogleFavorite() {
      if (this.user.id) {
        this.$store.dispatch("user/toggleFavorite", {
          vin: this.data.vin,
          fid: this.data.fid,
        });
        this.isFavorite = !this.isFavorite;
      } else {
        this.$root.$emit("login");
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    alternateCar(vin) {
      let car = this.selectedCar.alternatives.find((el) => el.vin == vin);
      let newData = {
        model: this.selectedCar.model,
        version: this.selectedCar.description,
        description: this.selectedCar.description,
        fid: car.financeId,
        isAvailable: car.availability == "STOCK" ? true : false,
        price: car.prices[0].actualValue,
        vin: car.vin,
        fee: car.monthlyPrice,
        formattedFee: formatPriceNumber(car.monthlyPrice, "2", "€/mês"),
        taeg: formatPriceNumber(car.taeg, "3", "%"),
        initEntrance: formatPriceNumber(car.depositValue, "2", "€"),
        nrPayments: car.financingMonths,
        capitalToFinance: formatPriceNumber(car.capitalFinance, "2", "€"),
        image: car.images.length > 4 ? car.images[4] : car.images[0],
        fuel: car.fuel,
        legalInfo: car.legalInfo,
        idFinancialProduct: car.idFinancialProduct,
        financialProduct: car.financialProduct,
        color: car.color,
        alternatives: this.selectedCar.alternatives,
        rotatingImages: car.images,
      };
      this.selectedCar = newData;
      this.componentUpdate++;
    },
  },
  beforeMount() {
    //this.newCarInfo = this.$store.getters.getSingleNewCarArray(16);
    this.selectedCar = { ...this.data };
    if (
      this.$store.state.user.favorites.find((el) => el == this.selectedCar.vin)
    ) {
      this.isFavorite = true;
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss" scoped>
@import "/Range";
</style>
