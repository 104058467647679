<template>
  <div class="fluid-element">
    <div class="range-slider-container" v-if="cars">
      <div class="range-slider">
        <vueper-slides
          class="no-shadow small-slider"
          :visible-slides="4"
          slide-multiple
          :slide-ratio="1 / 6"
          :dragging-distance="70"
          :breakpoints="breakpoints"
          :bullets="false"
          :arrows="true"
        >
          <template #arrow-left>
            <img
              :src="require('@/assets/arrow-slider.svg')"
              class="left-arrow"
            />
          </template>

          <template #arrow-right>
            <img
              :src="require('@/assets/arrow-slider.svg')"
              class="right-arrow"
            />
          </template>
          <vueper-slide
            v-for="(range, index) in cars"
            :key="index"
            :image="range.imageUrl"
            :link="'#' + range.modelId + '+' + range.model"
            :title="range.model"
            :class="
              range.mode == 0
                ? 'normal'
                : range.mode == 1
                ? 'selected'
                : range.mode == 2
                ? 'deselected'
                : ''
            "
            :content="'DESDE ' + formatPriceNumber(range.minPvp, 2, '€')"
          >
          </vueper-slide>
        </vueper-slides>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import { formatPriceNumber } from "@/functions/general.js";
import { getModels } from "@/services/functions.js";

import "vueperslides/dist/vueperslides.css";

export default {
  name: "RangeSlider",
  components: {
    VueperSlides,
    VueperSlide,
  },
  methods: {
    formatPriceNumber,
    getModels,
    restartSlider() {
      this.cars.forEach((car) => {
        car.mode = 0;
      });
      this.$forceUpdate();
    },
  },
  data: () => ({
    currentUrl: window.location,
    cars: [],
    breakpoints: {
      1400: {
        slideRatio: 1 / 6,
        visibleSlides: 4,
      },
      1200: {
        slideRatio: 1 / 5,
        visibleSlides: 3,
      },
      992: {
        slideRatio: 1 / 4,
        visibleSlides: 3,
      },
      768: {
        slideRatio: 1 / 2,
        visibleSlides: 2,
      },
      576: {
        slideRatio: 2 / 5,
        visibleSlides: 2,
      },
    },
  }),
  watch: {
    $route() {
      if (this.$route.hash.length > 0) {
        let modelId = this.$route.hash
          .substr(1, this.$route.hash.length - 1)
          .split("+")[0];
        this.cars.forEach((car) => {
          if (car.modelId != modelId) {
            car.mode = 2;
          } else {
            car.mode = 1;
          }
        });
      }
      this.$forceUpdate();
    },
  },
  created() {
    this.getModels().then((response) => {
      this.cars = response.data;
      this.cars = [
        ...new Map(this.cars.map((item) => [item["modelId"], item])).values(),
      ];
      this.cars.forEach((car) => {
        if (!car.imageUrl) {
          car.imageUrl = this.$store.state.isLexus
            ? require("@/assets/unavailable-lexus.png")
            : require("@/assets/unavailable-toyota.png");
        }
        car.mode = 0;
      });
      this.cars.sort((a, b) => a.minPvp - b.minPvp);
    });
  },
};
</script>
<style lang="scss">
@import "/Slider";
@import "/Range";
</style>
