var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.filterToggleState + 'usedCars-section'},[_c('div',{staticClass:"filter-header row"},[_c('div',{staticClass:"pointer row filter-label",on:{"click":function($event){return _vm.toggleContainer('filter')}}},[_c('p',[_vm._v("Filtros")]),_c('Arrow')],1),_c('div',[_c('p',[_c('span',[_vm._v(_vm._s(_vm.count))]),_vm._v(" Opções ")])]),_c('div',{staticClass:"pointer row order-label",attrs:{"id":"order","tabindex":"0"},on:{"focusout":function($event){_vm.orderToggleState = false},"click":function($event){_vm.orderToggleState = !_vm.orderToggleState}}},[_vm._v(" "+_vm._s(_vm.selectedOrder)),_c('Arrow'),_c('div',{class:_vm.orderToggleState ? 'option-panel' : 'option-panel close'},[_c('div',{staticClass:"custom-option",on:{"click":function($event){return _vm.changeOrder('availability', 'asc', 'Ordenar por Disponibilidade')}}},[_vm._v(" Ordenar por Disponibilidade ")]),_c('div',{staticClass:"custom-option",on:{"click":function($event){_vm.changeOrder('price', 'asc', 'Ordenar por Preço (Crescente)')}}},[_vm._v(" Ordenar por Preço (Crescente) ")]),_c('div',{staticClass:"custom-option",attrs:{"value":"priceDesc"},on:{"click":function($event){_vm.changeOrder('price', 'desc', 'Ordenar por Preço (Decrescente)')}}},[_vm._v(" Ordenar por Preço (Decrescente) ")]),_c('div',{staticClass:"custom-option",attrs:{"value":"nameAsc"},on:{"click":function($event){_vm.changeOrder(
              'monthlyPayment',
              'asc',
              'Ordenar por Renda (Crescente)'
            )}}},[_vm._v(" Ordenar por Renda (Crescente) ")]),_c('div',{staticClass:"custom-option",attrs:{"value":"nameDesc"},on:{"click":function($event){_vm.changeOrder(
              'monthlyPayment',
              'desc',
              'Ordenar por Renda (Decrescente)'
            )}}},[_vm._v(" Ordenar por Renda (Decrescente) ")])])],1)]),(_vm.getAllFilters.model)?_c('div',{staticClass:"filter-container"},[_c('ActiveFilter',{attrs:{"activeFilters":_vm.activeFilters,"appliedFilters":_vm.appliedFilters},on:{"onRemoveFilter":function($event){return _vm.removeFilter($event)},"onRemoveAllFilterType":_vm.removeType,"removeAllFilter":_vm.removeAllFilter}}),_c('FilterOptions',{ref:"filterOptions",attrs:{"filters":_vm.getAllFilters},on:{"checkboxFilterReplace":_vm.checkboxFilterReplace,"checkboxFilter":_vm.checkboxFilter,"rangeFilter":_vm.rangeFilter,"removeAllFilter":_vm.removeAllFilter}})],1):_vm._e(),(_vm.newCars)?_c('div',{key:_vm.update,staticClass:"usedCars-container on-range row"},[(!_vm.filterToggleState)?_c('div',{staticClass:"fluid-element elm-mobile"},[_c('div',{staticClass:"overlay"})]):_vm._e(),_vm._l((_vm.newCars),function(car,i){return _c('RangeSingleCard',{key:i,attrs:{"data":car}})})],2):_vm._e(),(_vm.isShowMore)?_c('div',{class:_vm.filterToggleState == 'filter-close '
        ? 'button-wrapper-closed'
        : 'button-wrapper'},[_c('button',{staticClass:"show-more",on:{"click":_vm.showMore}},[_vm._v("Mostrar Mais")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }