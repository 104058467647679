<template>
  <div :class="filterToggleState + 'usedCars-section'">
    <div class="filter-header row">
      <div class="pointer row filter-label" @click="toggleContainer('filter')">
        <p>Filtros</p>
        <Arrow />
      </div>
      <div>
        <p>
          <span>{{ count }}</span> Opções
        </p>
      </div>

      <div
        class="pointer row order-label"
        id="order"
        tabindex="0"
        @focusout="orderToggleState = false"
        @click="orderToggleState = !orderToggleState"
      >
        {{ selectedOrder }}<Arrow />
        <div :class="orderToggleState ? 'option-panel' : 'option-panel close'">
          <div
            class="custom-option"
            @click="
              changeOrder('availability', 'asc', 'Ordenar por Disponibilidade')
            "
          >
            Ordenar por Disponibilidade
          </div>
          <div
            class="custom-option"
            @click="
              changeOrder('price', 'asc', 'Ordenar por Preço (Crescente)')
            "
          >
            Ordenar por Preço (Crescente)
          </div>
          <div
            class="custom-option"
            @click="
              changeOrder('price', 'desc', 'Ordenar por Preço (Decrescente)')
            "
            value="priceDesc"
          >
            Ordenar por Preço (Decrescente)
          </div>
          <div
            class="custom-option"
            @click="
              changeOrder(
                'monthlyPayment',
                'asc',
                'Ordenar por Renda (Crescente)'
              )
            "
            value="nameAsc"
          >
            Ordenar por Renda (Crescente)
          </div>
          <div
            class="custom-option"
            @click="
              changeOrder(
                'monthlyPayment',
                'desc',
                'Ordenar por Renda (Decrescente)'
              )
            "
            value="nameDesc"
          >
            Ordenar por Renda (Decrescente)
          </div>
        </div>
      </div>
    </div>
    <div class="filter-container" v-if="getAllFilters.model">
      <ActiveFilter
        :activeFilters="activeFilters"
        :appliedFilters="appliedFilters"
        @onRemoveFilter="removeFilter($event)"
        @onRemoveAllFilterType="removeType"
        @removeAllFilter="removeAllFilter"
      />
      <FilterOptions
        :filters="getAllFilters"
        @checkboxFilterReplace="checkboxFilterReplace"
        @checkboxFilter="checkboxFilter"
        @rangeFilter="rangeFilter"
        @removeAllFilter="removeAllFilter"
        ref="filterOptions"
      />
    </div>

    <div v-if="newCars" class="usedCars-container on-range row" :key="update">
      <div class="fluid-element elm-mobile" v-if="!filterToggleState">
        <div class="overlay"></div>
      </div>
      <RangeSingleCard v-for="(car, i) in newCars" :key="i" :data="car" />
    </div>
    <div
      :class="
        filterToggleState == 'filter-close '
          ? 'button-wrapper-closed'
          : 'button-wrapper'
      "
      v-if="isShowMore"
    >
      <button class="show-more" @click="showMore">Mostrar Mais</button>
    </div>
  </div>
</template>

<script>
import ActiveFilter from "@/components/FilterUsedCars/ActiveFilter.vue";
import RangeSingleCard from "@/components/RangeList/RangeSingleCard.vue";
import Arrow from "@/assets/Arrow.vue";
import FilterOptions from "@/components/FilterUsedCars/FilterOptions.vue";

import { filterCarNew } from "@/services/functions.js";

export default {
  name: "FilterNewCars",
  components: {
    ActiveFilter,
    Arrow,
    FilterOptions,
    RangeSingleCard,
  },
  data() {
    return {
      filterToggleState: "filter-close ",
      orderToggleState: false,
      initialValues: [],
      labelValues: [],
      activeFilters: [],
      steps: [],
      dealerSelected: this.$route.params.dealer,
      modelSelected: this.$route.params.model,
      newCars: [],
      update: 0,
      page: 1,
      order: {},
      selOrder: "Ordenar por Disponibilidade",
    };
  },
  computed: {
    appliedFilters() {
      return this.$store.state.appliedFilter;
    },
    getAllFilters() {
      return this.$store.state.filtersNovos;
    },
    count() {
      return this.$store.state.novosCount;
    },
    isShowMore() {
      return this.$store.state.novosShowMore;
    },
    selectedOrder() {
      return this.selOrder;
    },
  },
  props: {
    data: { type: Array },
  },
  created() {
    this.removeAllFilter();
  },
  watch: {
    "$store.state.novos": function () {
      this.newCars = this.$store.getters.getMappedCarArray;
    },
  },
  methods: {
    filterCarNew,
    toggleContainer(container) {
      switch (container) {
        case "filter":
          this.filterToggleState =
            this.filterToggleState == "" ? "filter-close " : "";
          break;
        default:
      }
    },
    changeOrder(type, order, newSel) {
      this.order = { type, order };
      this.selOrder = newSel;
      this.getNewData();
    },
    showMore() {
      this.page++;
      this.$store.commit("SET_LOADING", true);
      this.filterCarNew(
        { filters: this.$store.state.appliedFilters, order: {} },
        this.page
      ).then((response) => {
        this.$store.commit("ADD_NOVOS", {
          data: response.data,
          isShowMore: response.pagination.totalResults,
        });
        this.$store.commit("SET_LOADING", false);
      });
    },
    checkboxFilter(event) {
      if (event.value.checked == true) {
        this.$store.commit("ADD_FILTER_CHECKBOX", event.type);
      } else {
        this.$store.commit("REMOVE_FILTER", event.type);
      }
      this.getNewData();
    },
    checkboxFilterReplace(event) {
      if (event.value.checked == true) {
        let inputs = document.getElementsByTagName("input");
        for (let i = 0; i < inputs.length; i++) {
          if (inputs[i].className == "checkbox") {
            inputs[i].checked = false;
          }
        }
        this.$store.commit("REMOVE_ALL_FILTER", 1);
        let checkBox = document.getElementById("model" + event.type.id);
        checkBox.checked = true;
        this.$store.commit("ADD_FILTER_CHECKBOX", event.type);
      } else {
        this.$store.commit("REMOVE_FILTER", event.type);
      }

      this.getNewData();
    },
    // FUNCTION CALLED WHEN USER CHANGES ANY SETTING ON THE FILTER WITH RANGES
    rangeFilter(event) {
      this.$store.commit("ADD_FILTER_RANGE", event);
      this.getNewData();
    },
    // FUNCTION CALLED WHEN USER CLEARS ONE FILTER
    // FUNCTION CALLED WHEN USER CLEARS ONE FILTER
    removeFilter: function (prop) {
      let but = document.getElementById(prop.type + prop.id);
      if (but) {
        if (but.checked) {
          but.checked = false;
        }
      }
      if (prop.type == "price") {
        let priceSup = document.getElementById("priceSup_sliderMax");
        let priceInf = document.getElementById("priceInf_sliderMin");
        let priceSupInput = document.getElementById("priceSup_inputMax");
        let priceInfInput = document.getElementById("priceInf_inputMin");
        if (priceSup && priceInf) {
          priceSup.value = priceSup.max;
          priceInf.value = priceInf.min;
          priceSupInput.firstElementChild.value = priceSup.max;
          priceInfInput.firstElementChild.value = priceInf.min;
        }
        if (this.$refs.filterOptions) {
          this.$refs.filterOptions.resetRange("price");
        }
      }
      if (prop.type == "fee") {
        let feeSup = document.getElementById("monthlyPaymentSup_sliderMax");
        let feeInf = document.getElementById("monthlyPaymentInf_sliderMin");
        let feeSupInput = document.getElementById("monthlyPaymentSup_inputMax");
        let feeInfInput = document.getElementById("monthlyPaymentInf_inputMin");
        if (feeSup && feeInf) {
          feeSup.value = feeSup.max;
          feeInf.value = feeInf.min;
          feeSupInput.firstElementChild.value = feeSup.max;
          feeInfInput.firstElementChild.value = feeInf.min;
        }
        if (this.$refs.filterOptions) {
          this.$refs.filterOptions.resetRange("fee");
        }
      }
      if (
        prop.id ==
        this.$route.hash.substr(1, this.$route.hash.length - 1).split("+")[0]
      ) {
        this.$emit("restartSlider");
      }
      this.$store.commit("REMOVE_FILTER", prop);
      this.getNewData();
    },
    removeType: function (arr) {
      arr.forEach((element) => {
        let but = document.getElementById(element.type + element.id);
        if (but) {
          if (but.checked) {
            but.checked = false;
          }
        }
        if (
          element.id ==
          this.$route.hash.substr(1, this.$route.hash.length - 1).split("+")[0]
        ) {
          this.$emit("restartSlider");
        }
        this.$store.commit("REMOVE_FILTER", element);
      });
      this.getNewData();
    },
    // FUNCTION CALLED WHEN USER CLEARS ALL FILTERS
    removeAllFilter: function () {
      let inputs = document.getElementsByTagName("input");
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].className == "checkbox") {
          inputs[i].checked = false;
        }
      }
      if (document.getElementById("priceSup_sliderMax")) {
        document.getElementById("priceSup_sliderMax").value =
          document.getElementById("priceSup_sliderMax").max;
        document.getElementById("priceInf_sliderMin").value =
          document.getElementById("priceInf_sliderMin").min;
        document.getElementById("monthlyPaymentSup_sliderMax").value =
          document.getElementById("monthlyPaymentSup_sliderMax").max;
        document.getElementById("monthlyPaymentInf_sliderMin").value =
          document.getElementById("monthlyPaymentInf_sliderMin").min;
        if (this.$refs.filterOptions) {
          this.$refs.filterOptions.resetRange();
        }
      }

      this.$emit("restartSlider");
      this.$store.commit("REMOVE_ALL_FILTER", 1);
      this.getNewData();
    },
    async getNewData() {
      this.$store.commit("SET_LOADING", true);
      let orderValues = {};
      if (this.order) {
        orderValues = this.order;
      }
      await Promise.all([
        this.$store.dispatch("getNewFilters", {
          condition: 1,
          filters: this.$store.state.appliedFilters,
        }),
        this.$store.dispatch("getSearchedNew", {
          filters: this.$store.state.appliedFilters,
          order: orderValues,
        }),
      ]);

      this.update++;
      this.$store.commit("SET_LOADING", false);
    },
    // FUNCTION CALLED WHEN USER SELECTS A SLIDER MODEL
  },
};
</script>

<style lang="scss" scoped>
@import "/FilterUsedCars";
@import "../RangeList/Range";
@import "@/components/UsedCars/UsedCars.scss";
</style>
