import * as service from "../services/functions";
/* EMPTY FILTER WITH ALL THE PROPERTIES FOR REFERENCE */
export let emptyFilter = {};

/* ARRAY THAT SAVES THE VALUES USED IN THE FILTERS - USED FOR THE TOOLTIPS OF THE FILTERS */
export let activeFilters = [];

/* INITIAL FILTER WITH ALL THE VALUES AND REAL MIN AND MAX VALUES */
export let initialFilter = {};

/* FILTER APPLIED TO THE CARS ARRAY WITH THE VALUES ONLY SELECTED TO FILTER AND THE MIN AND MAX SET */
export let appliedFilter = {};

/* FILTER OF REFERENCE FOR THE FILTER ELEMENT TO KNOW THE LENGTH OF RESULTS FOR EACH VALUE */
export let referenceFilter = {};

export let firstLoad = false;

/* OBJECT WITH SETTINGS FOR THE PROPERTIES
 * IF PROP IS A LIST OR RANGE | IF IT IS A PRICE | IF IT'S TO FORMAT BY THE THOUSANDS
 */
let propSettings = {};

/* ARRAY THAT SAVES ALL THE VALUES OF THE MIN MAX PROPERTIES TO CREATE THE STEPS IN THE RANGE */
export let steps = [];

export function initFilters(propertySet, propertySettings) {
  /* SET THE PROPERTIES THAT WILL BE FILTERED */

  emptyFilter = propertySet;
  propSettings = propertySettings;

  /* INITIAL FILTER WITH ALL THE VALUES AND REAL MIN AND MAX VALUES */
  initialFilter = JSON.parse(JSON.stringify(emptyFilter));

  /* FILTER APPLIED TO THE CARS ARRAY WITH THE VALUES ONLY SELECTED TO FILTER AND THE MIN AND MAX SET */
  appliedFilter = JSON.parse(JSON.stringify(emptyFilter));

  /* FILTER OF REFERENCE FOR THE FILTER ELEMENT TO KNOW THE LENGTH OF RESULTS FOR EACH VALUE */
  referenceFilter = JSON.parse(JSON.stringify(emptyFilter));
}

/* FUNCTION TO RESTART FILTERS ENTIRELY */
export function resetFilter() {
  emptyFilter = {};
  activeFilters = [];
  initialFilter = {};
  appliedFilter = {};
  referenceFilter = {};
  firstLoad = false;
  propSettings = {};
  steps = [];
}

/* FIRST FUNCTION TO BUILD INITIAL FILTER WITH ALL THE VALUES */
export function firstFilterLoad(array, isNew = false) {
  firstLoad = true;
  /* ITERATES THE ARRAY AND CALLS THE FUNCTION TO FEED THE FILTER WITH EACH PROPERY WITH A LIST OF VALUES */
  if (isNew) {
    propSettings.propWList.forEach((prop) => {
      if (prop == "color" || prop == "fuelType") {
        createFilterList(array, prop, initialFilter, true);
      } else {
        createFilterList(array, prop, initialFilter, false);
      }
    });
  } else {
    propSettings.propWList.forEach((prop) => {
      createFilterList(array, prop, initialFilter, false);
    });
  }

  /* ITERATES THE ARRAY AND CALLS THE FUNCTION TO FEED THE FILTER WITH EACH PROPERY WITH A MIN AND MAX VALUES */
  propSettings.propWMinMax.forEach((prop) => {
    createFilterMinMax(array, prop, initialFilter);
    createSteps(array, prop, steps);
  });
  /* SETS THE REFERENCE FILTER THE SAME AS THE INITIAL FILTER ON THE LOAD OF THE PAGE */

  let newArrCars = {};

  Object.keys(initialFilter).map((x) => {
    newArrCars[x] = initialFilter[x];
  });
  referenceFilter = JSON.parse(JSON.stringify(newArrCars));
}

/* FUNCTION GETS THE UNIQUE VALUES FOR THE STEPS */
function createSteps(array, key, steps) {
  let values = getUniques(array, key);

  values = values.map((value) => {
    return parseInt(value);
  });
  steps[key] = values;
}

/* FUNCTION THAT IS CALLED TO  UPDATE APPLIED FILTER WHEN THE USER CHANGES THE FILTER
    KEY: PROPERTY FROM WHERE THE VALUES IS PART OF 
    VALUE: VALUE THAT THE USER SELECTED
    CHECKED: BOOLEAN IF USER CHECKED THE VALUE OR UNCHECKED
*/
export function updateAppliedCheckboxFilter(
  array,
  key,
  value,
  checked,
  isMultiple = false
) {
  /* IF USER CHECKED THE VALUE PUSHES THE VALUE THE APPLIED FILTER
    ELSE SEARCHES FOR THE VALUE IN THE APPLIED FILTER AND REMOVES IT
  */
  if (checked) {
    appliedFilter[key].push(value);
  } else {
    const index = appliedFilter[key].findIndex((x) => x === value);

    appliedFilter[key].splice(index, 1);
  }

  /* ADDS VALUE TO APPLIED FILTER OR REMOVES IS */
  if (appliedFilter[key].length > 0) {
    addToActiveFilter(key);
  } else {
    removeFromActiveFilter(key);
  }
  /* AFTER APPLIED FILTER IS SET, CALLS THE FUNCTION TO UPDATE THE LIST OF RESULTS */

  return updateArray(array, isMultiple);
}

/* FUNCTION THAT IS CALLED TO  UPDATE APPLIED FILTER WHEN THE USER CHANGES THE FILTER
    KEY: PROPERTY FROM WHERE THE VALUES IS PART OF 
    VALUE: VALUE THAT THE USER SELECTED
    RANGE: TELLS IF THE VALUE IS FOR THE MIN OR MAX
*/
export function updateAppliedRangeFilter(array, key, value, range) {
  let finalValue = parseInt(value);

  if (range == "min") {
    /* IF THE VALUE OF MIN IS HIGHER THAN THE MAX PUTS THE MIN VALUE THE SAME AS THE MAX TO PREVENT ERRORS */
    if (appliedFilter[key].max < value && appliedFilter[key].max != null) {
      finalValue = appliedFilter[key].max;
    } else if (initialFilter[key].max < value) {
      finalValue = initialFilter[key].max;
    }
    /* IF VALUE IS THE SAME AS THE ONE IN THE INITIAL FILTER, PUTS THE APPLIED FILTER AS NULL TO RESET THE FILTER ELSE FORMATS THE NUMBER */
    appliedFilter[key].formattedMin =
      finalValue == initialFilter[key][range]
        ? null
        : propSettings.propFormat.includes(key)
        ? formatNumber(finalValue, key)
        : finalValue;
  } else if (range == "max") {
    /* IF THE VALUE OF MAX IS LOWER THAN THE MIN PUTS THE MAX VALUE THE SAME AS THE MIN TO PREVENT ERRORS */
    if (appliedFilter[key].min > value && appliedFilter[key].min != null) {
      finalValue = appliedFilter[key].min;
    } else if (initialFilter[key].min > value) {
      finalValue = initialFilter[key].min;
    }
    /* IF VALUE IS THE SAME AS THE ONE IN THE INITIAL FILTER, PUTS THE APPLIED FILTER AS NULL TO RESET THE FILTER ELSE FORMATS THE NUMBER */
    appliedFilter[key].formattedMax =
      finalValue == initialFilter[key][range]
        ? null
        : propSettings.propFormat.includes(key)
        ? formatNumber(finalValue, key)
        : finalValue;
  }

  /* IF VALUE IS THE SAME AS THE ONE IN THE INITIAL FILTER, PUTS THE APPLIED FILTER AS NULL TO RESET THE FILTER ELSE FORMATS THE NUMBER */
  appliedFilter[key][range] =
    finalValue == initialFilter[key][range] ? null : finalValue;

  /* IF THE APPLIED FILTERS ARE NULL REMOVES THE PROP FROM THE APPLIED FILTER TO REMOVE THE TOOLTIP */
  if (appliedFilter[key].min == null && appliedFilter[key].max == null) {
    removeFromActiveFilter(key);
  } else {
    addToActiveFilter(key);
  }

  return updateArray(array);
}

/* FUNCTION TO CLEAR SINGLE FILTER - CALLED FROM THE DELETE BUTTON ON THE INTERFACE
 * ARRAY - WHOLE ARRAY TO FILTER
 * PROP - TOOLTIP OF PROP TO REMOVE
 */
export function clearFilter(array, prop) {
  clearUniqueFilter(prop);
  return updateArray(array);
}

/* FUNCTION TO REMOVE ALL THE FILTERS */
export function clearAllFilters(array) {
  /* ITERATES THROUGH THE PROPS TO CLEAR EACH PROP */
  propSettings.propWList.forEach((prop) => {
    clearUniqueFilter(prop);
  });
  propSettings.propWMinMax.forEach((prop) => {
    clearUniqueFilter(prop);
  });

  if (array) {
    return updateArray(array);
  }
}

/* GENERAL FUNCTION TO CLEAR FILTER */
function clearUniqueFilter(prop) {
  /* SETS THE APPLIED FILTER BACK TO THE START WITH NO VALUES */

  appliedFilter[prop] = JSON.parse(JSON.stringify(emptyFilter[prop]));

  /* CHECK IF HAS CHECKBOXES AND PUTS ALL TO FALSE */
  const allPropCheckboxes = document.querySelectorAll(
    "[data-type=" + prop + "]"
  );
  allPropCheckboxes.forEach((checkbox) => {
    checkbox.checked = false;
  });

  removeFromActiveFilter(prop);
}

/* ADDS KEY FROM THE APPLIED FILTER */
function addToActiveFilter(key) {
  !activeFilters.includes(key) ? activeFilters.push(key) : "";
}

/* REMOVES KEY FROM THE APPLIED FILTER */
function removeFromActiveFilter(key) {
  const index = activeFilters.findIndex((x) => x === key);
  index > -1 ? activeFilters.splice(index, 1) : "";
}

/* FUNCTION TO UPDATE THE LIST OF RESULTS */
function updateArray() {
  const keys = [];
  const values = [];
  const minMaxKeys = [];
  let minMaxValues = {};
  /* ITERATES THE ARRAY WITH THE PROPERTIES WITH LIST OF VALUES TO FEED THE KEYS AND VALUES ARRAYS TO FILTER THE RESULTS */
  propSettings.propWList.forEach((prop) => {
    /* CHECKS IF PROPERTY HAS ANY VALUE IN THE APPLIED FILTER */
    if (appliedFilter[prop].length > 0) {
      /* PUSHES THE KEY OF THE PROPERTY TO FILTER BY TO THE KEYS ARRAY */
      keys.push(prop);
      /* PUSHES ALL THE VALUES OF THE PROPERTY TO FILTER BY TO THE VALUES ARRAY */
      appliedFilter[prop].map(function (single) {
        values.push(single);
      });
    }
  });

  propSettings.propWMinMax.forEach((prop) => {
    minMaxKeys.push(prop);
    const minValue =
      appliedFilter[prop].min == null
        ? initialFilter[prop].min
        : appliedFilter[prop].min;
    const maxValue =
      appliedFilter[prop].max == null
        ? initialFilter[prop].max
        : appliedFilter[prop].max;

    minMaxValues[prop] = {
      min: parseInt(minValue),
      max: parseInt(maxValue),
    };
  });

  service.filterCar(appliedFilter).then((res) => {
    return res;
  });

  /* CALLS FUNCTION TO FILTER LIST OF RESULTS BASED ON THE VALUES SET PREVIUSLY 
  filteredArray = simpleFilter(array, keys, values, minMaxKeys, minMaxValues);
  
  CALLS FUNCTION TO UPDATE THE REFERENCE FILTER TO KNOW THE LENGHTH OF RESULTS FOR EACH VALUE 
  updateReferenceFilter(filteredArray); */
}

/* FUNCTION TO UPDATE THE REFERENCE FILTER TO KNOW THE LENGHTH OF RESULTS FOR EACH VALUE */
// eslint-disable-next-line no-unused-vars
function updateReferenceFilter(array) {
  /* ITERATES THE ARRAY WITH THE PROPERTIES WITH A LIST OF VALUES */
  propSettings.propWList.forEach((prop) => {
    /* ITERATES EACH VALUED ARRAY (VALUE AND LENGTH) INSIDE THE REFERENCE FILTER */
    referenceFilter[prop].forEach((value) => {
      /* GETS THE LENGTH OF THE RESULTS WITH THAT VALUE AND UPDATES IT IN THE REFERENCE FILTER */

      value.length = getLength(array, prop, value.value);
    });
  });

  propSettings.propWMinMax.forEach((prop) => {
    createFilterMinMax(array, prop, referenceFilter);
  });

  propSettings.propWMinMax.forEach((prop) => {
    const minValue =
      appliedFilter[prop].min == null
        ? referenceFilter[prop].min
        : appliedFilter[prop].min;
    const maxValue =
      appliedFilter[prop].max == null
        ? referenceFilter[prop].max
        : appliedFilter[prop].max;

    referenceFilter[prop].min = minValue;
    referenceFilter[prop].formattedMin = propSettings.propFormat.includes(prop)
      ? formatNumber(minValue, prop)
      : minValue;
    referenceFilter[prop].max = maxValue;
    referenceFilter[prop].formattedMax = propSettings.propFormat.includes(prop)
      ? formatNumber(maxValue, prop)
      : maxValue;
  });
}

/* FUNCTION TO FILTER LIST OF RESULTS BASED ON THE VALUES SENT */
// eslint-disable-next-line no-unused-vars
function simpleFilter(
  arrayToSearch,
  keysToCompare,
  valuesToLook,
  minMaxKeys,
  minMaxValues
) {
  let filter;
  filter = arrayToSearch.filter((el) => {
    return keysToCompare.every(function (a) {
      if (a != "color" && a != "fuelType") {
        return valuesToLook.includes(el[a]);
      } else {
        let isThis = false;
        el.carState.forEach((element) => {
          if (valuesToLook.includes(element[a])) {
            el.state = element.stateId;
            isThis = true;
          }
        });
        return isThis;
      }
    });
  });

  filter = filter.filter((el) => {
    return minMaxKeys.every(function (a) {
      return (
        minMaxValues[a].min - 1 <= el[a] && minMaxValues[a].max + 1 >= el[a]
      );
    });
  });
  return filter;
}

/* CREATES THE ARRAYS WITH LISTS - ARRAY: LIST OF THE RESULTS / KEY: PROPERTY TO LOOK FOR */
function createFilterList(array, key, filter, isMultiple) {
  /* CALLS A FUNCTION TO FIND ALL THE UNIQUES VALUES OF THAT PROPERTY */
  let values;
  if (isMultiple) {
    values = getUniquesMultiple(array, key);
  } else {
    values = getUniques(array, key);
  }

  /* ITERATES EACH PROPERTY AND GETS THE LENGTH OF THE RESULTS WITH THAT VALUE AND PUSHES TO THE INITIAL ARRAY */
  values.forEach((value) => {
    let valueLength;
    if (isMultiple) {
      valueLength = getLengthMultiple(array, key, value);
    } else {
      valueLength = getLength(array, key, value);
    }
    if (filter[key]) {
      filter[key].push({ value: value, length: valueLength });
    }
  });
}

/* CREATES THE ARRAYS WITH MIN AND MAX - ARRAY: LIST OF THE RESULTS / KEY: PROPERTY TO LOOK FOR */
function createFilterMinMax(array, key, filter) {
  /* MAPS THE ARRAY TO GET THE MINIMUM VALUE IN THE ARRAY OF THAT PROPERTY */
  const minValue = Math.min.apply(
    Math,
    array.map(function (single) {
      return single[key];
    })
  );

  /* MAPS THE ARRAY TO GET THE MAXIMUM VALUE IN THE ARRAY OF THAT PROPERTY */
  const maxValue = Math.max.apply(
    Math,
    array.map(function (single) {
      return single[key];
    })
  );

  /* SETS THE MIN AND MAX VALUES OF THAT PROPERTY IN THE INITIAL ARRAY */

  if (filter[key]) {
    filter[key].min = minValue == Infinity ? initialFilter[key].min : minValue;
    filter[key].max = maxValue == -Infinity ? initialFilter[key].max : maxValue;
    filter[key].formattedMin = propSettings.propFormat.includes(key)
      ? formatNumber(filter[key].min, key)
      : filter[key].min;
    filter[key].formattedMax = propSettings.propFormat.includes(key)
      ? formatNumber(filter[key].max, key)
      : filter[key].max;
  }
}

/*
 * GENERAL FUNCTIONS
 */

/* GET ALL THE DIFFERENT MODELS, FUEL TYPES AND LOCATIONS */
function getUniques(array, key) {
  const flag = {};
  const unique = [];
  array.forEach((el) => {
    if (!flag[el[key]]) {
      flag[el[key]] = true;
      unique.push(el[key]);
    }
  });
  return unique.sort();
}

// GET ALL DIFFERENT PROPS OF MULTIPLE STATES
function getUniquesMultiple(array, key) {
  const flag = {};
  const unique = [];
  array.forEach((element) => {
    element.carState.map((el) => {
      if (!flag[el[key]]) {
        flag[el[key]] = true;
        unique.push(el[key]);
      }
    });
  });
  return unique;
}

/* GET THE NUMBER OF CARS FOR EACH VALUE OF MODEL, FUEL TYPE AND LOCATION FROM CARSTATE*/
function getLengthMultiple(array, key, value) {
  let count = 0;
  array.forEach((el) => {
    el.carState.forEach((element) => {
      if (element[key] === value) {
        count++;
      }
    });
  });
  return count;
}

/* GET THE NUMBER OF CARS FOR EACH VALUE OF MODEL, FUEL TYPE AND LOCATION */
function getLength(array, key, value) {
  return array.filter((v) => v[key] === value).length;
}

export function formatNumber(value, key = null, symbol = true) {
  if (propSettings.propFormat.includes(key)) {
    symbol =
      symbol == false ? "" : propSettings.propWPrice.includes(key) ? " €" : "";
    return (
      value
        .toString()
        .replace(/\s+/g, "")
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + symbol
    );
  }

  return value;
}

// FUNCTION TO ORDER AN ARRAY BY GIVEN KEY
export function orderFilter(arrayToSearch, keyToOrderBy, order) {
  if (order == "asc") {
    arrayToSearch.sort(function (a, b) {
      return a[keyToOrderBy] - b[keyToOrderBy];
    });
  } else if (order == "desc") {
    arrayToSearch.sort(function (a, b) {
      return b[keyToOrderBy] - a[keyToOrderBy];
    });
  }
  return arrayToSearch;
}

// FUNCTION TO ORDER THROUGH TWO DIFFERENT KEYS, FIRSTLY A BOOLEAN; THEN ORDER EACH THROUGH NUMBER
export function orderBoolKey(arrayToOrder, bool, key) {
  let firstArray = [];
  let secondArray = [];
  arrayToOrder.forEach((element) => {
    if (element[bool]) {
      firstArray.push(element);
    } else {
      secondArray.push(element);
    }
  });
  firstArray.sort(function (a, b) {
    return a[key] - b[key];
  });
  secondArray.sort(function (a, b) {
    return a[key] - b[key];
  });
  return [...firstArray, ...secondArray];
}

// Function to order throught the array and set the highest stock version as the selected
export function orderThroughStock(array) {
  array.forEach((el) => {
    el.state = el.carState.hasMax("stock").stateId;
  });
  return array;
}
Array.prototype.hasMax = function (attrib) {
  return (
    (this.length &&
      this.reduce(function (prev, curr) {
        return prev[attrib] > curr[attrib] ? prev : curr;
      })) ||
    null
  );
};
