<template>
  <div class="wrapper main">
    <RangeSlider ref="slider" />
    <Section>
      <FilterNewCars @restartSlider="restartSlider" />
    </Section>
    <Section>
      <HeadingBlock
        :data="{ blockText: novosLegal }"
        textClass="legal "
        blockClass="full-width "
      />
    </Section>
    <Section v-if="seo">
      <SEO
        :seo="seo.general"
        :banner="seo.general.seoBannerImage"
        :link="seo.general.seoBannerLink"
      />
    </Section>
  </div>
</template>

<script>
import HeadingBlock from "@/components/Blocks/HeadingBlock.vue";
import FilterNewCars from "@/components/FilterUsedCars/FilterNewCars.vue";
import RangeSlider from "@/components/RangeList/RangeSlider.vue";
import Section from "@/components/Section/Section.vue";
import SEO from "@/components/SEO/SEO.vue";

import { orderBoolKey } from "@/functions/filter.js";

import { manageSEO } from "@/services/dataManagement.js";

import { getFiltersNew } from "../services/functions";

export default {
  name: "Gama",
  metaInfo() {
    return manageSEO(this.seo, this.seoHomepage, false, this.dealerName);
  },
  components: {
    HeadingBlock,
    FilterNewCars,
    RangeSlider,
    Section,
    SEO,
  },
  methods: {
    orderBoolKey,
    getFiltersNew,
    restartSlider() {
      this.$refs.slider.restartSlider();
    },
  },
  data() {
    return {
      dealerName: this.$store.state.dealerInfo.dealerName,
      userToken: this.$store.state.user.loginToken,
      navBarCars: [],
    };
  },
  created() {
    if (window.location.href.split("#")[1]) {
      window.location.href = window.location.href.split("#")[0];
    }
  },
  computed: {
    novosLegal() {
      return this.$store.state.novosLegal;
    },
    seo() {
      return this.$store.state.novosSEO;
    },
    seoHomepage() {
      return this.$store.state.homepage.seo.general;
    },
  },
};
</script>
